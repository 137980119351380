<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                title="Visit Bank Client"
                @onClickCloseButton="onClickCloseButton"
            />
        </div>
       
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                    <label class="form-label">Start Time</label>
                    <div class="input-group input-group-merge w-100">
                        <input
                            v-model="formData.start_time"
                            name="datetime"
                            type="datetime-local"
                            class="form-control"
                        />
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                    <label class="form-label">End Time</label>
                    <div class="input-group input-group-merge w-100">
                        <input
                            v-model="formData.end_time"
                            name="datetime"
                            type="datetime-local"
                            class="form-control"
                        />
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                    <label class="form-label">BDO</label>
                    <AsyncSelect
                        placeholder="Select BDO name"
                        v-model="saleRepresentative"
                        :api-service="fetchAllSR"
                        :disabled="true"
                    />
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                    <label class="form-label">Select Bank</label>
                    <AsyncSelect
                        placeholder="Select Bank"
                        v-model="singleBank"
                        :api-service="fetchContactProfiles"
                        :additional-query="{ type: 'dealer'}"
                    />
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                    <label class="form-label">Bank-Branch</label>
                    <AsyncSelect
                        v-model="singleDealer"
                        placeholder="Select Bank-Branch"
                        :api-service="fetchAllDealers"
                        :additional-query="dealerQuery"
                        :key="dealerRenderKey"
                    />
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                    <label class="form-label">Short Description</label>
                    <div class="input-group input-group-merge w-100">
                        <input
                            name="name"
                            v-model.trim="formData.short_description"
                            type="text"
                            class="form-control"
                            placeholder="Enter short description"
                        />
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                    <label class="form-label">Full Description</label>
                    <div class="input-group input-group-merge w-100">
                        <textarea 
                            v-model.trim="formData.full_description"
                            name="reference" rows="2"
                            placeholder="Enter full description"
                            class="form-control add-credit-card-mask"
                        >
                        </textarea>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <hr class="mt-2">
                    <SubmitCancelBtn 
                        class="text-center"
                        @onSubmit="submit"
                        @onCancel="onClickCloseButton"
                        :loading="loading"
                        submitTxt="Submit"
                    />
                </div>
                <div class="col-12 px-2">
                    <hr class="mt-3 mb-3">
                    <ListTable
                        :tableItems="tableItems"
                    />
                </div>
                <div class="px-2 position-absolute bottom-0 mt-4">
                    <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
                </div>

                <Loader v-if="loading"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/crm/visit-plan/BankVisitClientTable.vue';
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import SubmitCancelBtn from '@/components/atom/SubmitCancelBtn.vue';
import { getCurrentDateTime } from '@/services/utils/global.js'
import { inject, ref, watch, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import handleCrm from '@/services/modules/crm/crm.js'
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import handleContact from '@/services/modules/contact';

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false);
const { fetchAllDealers, fetchBankVisitClientList, fetchAllSR, fetchSRDetailsByUser, storeVisitPlan, updateVisitPlan } = handleCrm();
const { fetchContactProfiles } = handleContact();
const singleDealer = ref(null);
const singleBank = ref(null);
const saleRepresentative = ref(null);
const tableItems = ref([]);
const dealerQuery = ref({
    sale_representative_id: '',
    contact_profile_id: ''
});
const dealerRenderKey = ref(0);
const params = reactive({
    searchTxt: '',
    offset: 10
});
const formData = ref({
    company_id: null,
    contact_profile_id: null,
    sale_representative_id: null,
    dealer_id: null,
    area_name: '',
    start_time: getCurrentDateTime(),
    end_time: '',
    short_description: '',
    full_description: '',
    contacts: [],
    status: 'active'
});

const onPageChange = async (page) => {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    getVisitClientsList();
}

const onClickCloseButton = () => {
    router.push({
        name: route.query.from ? route.query.from : 'visit-plan-list',
        params: route.params,
        query: { start: route.query.start, end: route.query.end}
    })
}

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page
    if(params.searchTxt) query += `&search_txt=${params.searchTxt}`;
    query += '&offset=' + params.offset
    return query;
}

const getVisitClientsList = async () => {
    const query = getQuery();
    loading.value = true

    await fetchBankVisitClientList(query, singleDealer.value.id).then(res => {
        loading.value = false
        if (!res.status){
            tableItems.value = []
            store.commit("resetPagination")
            return
        }
        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const resetPage = () => {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = 1
    router.push({path: route.path, query: routeQuery})
}

const resetClientList = () => {
    tableItems.value = [];
    store.commit('resetPagination')
    resetPage();
}

const submit = async () => {

    const formattedData = formatFormData();
    loading.value = true

    await storeVisitPlan(formattedData).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message);
        onClickCloseButton();
        return showSuccess(res.message);
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const formatFormData = () => {
    
    const newFormData = structuredClone(formData.value);
    newFormData.company_id = route.params.companyId;

    if(saleRepresentative.value) {
        newFormData.sale_representative_id = saleRepresentative.value.id
    }

    if(singleDealer.value) {
        newFormData.dealer_id = singleDealer.value.id
    }

    return newFormData;
}

const getSingleSR = async () => {
    const companyQuery = `?company_id=${route.params.companyId}`
    await fetchSRDetailsByUser(companyQuery, store.state.user.user.id).then((res) => {
        if (res.status) {
            saleRepresentative.value = {
                id: res.data.id,
                name: res.data.name
            }
            dealerQuery.value = { sale_representative_id : res.data.id }
        }
    })
}

watch(() => singleDealer.value, (newValue, oldValue) => {
    if(!newValue) {
        resetClientList();
        return;
    }
    getVisitClientsList();
})

watch(() => singleBank.value, (newValue, oldValue) => {
    singleDealer.value = null;
    dealerQuery.value.contact_profile_id = '';
    dealerRenderKey.value += 1;
    resetClientList();

    if(newValue) {
        dealerQuery.value.contact_profile_id = newValue.id;
    }

})

onMounted(() => {
    resetClientList();
    getSingleSR();
})

</script>
